<template>
  <div class="page-header">
      <div class="merlyn-said">Merlyn said:</div>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.page-header {
    height: auto;
}
.merlyn-said {
    margin: 20px;
    display: flex;
    font-size: 30px;
    font-weight: 100px;
    letter-spacing: 1px;
    color: rgba(45, 71, 97, 0.7);
    font-family: "hanyi", Calibri, "Trebuchet MS", sans-serif;
    justify-content: flex-start;
}
</style>