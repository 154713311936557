<template>
  <div class="bubble">
    <div class="bubble-title">{{ title }}</div>
    <div class="bubble-quote">
      <p v-html="quote"></p>
    </div>
    <div class="timestamp">{{ timestring }}</div>
  </div>
</template>

<script>
export default {
  props: ["quote", "title", "time"],
  data() {
      return {
          timestring: "----/--/-- --:--:--",
      }
  },
  mounted() {
      this.post_time = new Date(this.time)
      this.timestring = `${this.post_time.toLocaleDateString()} ${this.post_time.toLocaleTimeString()}`
  },
};
</script>

<style>
.bubble {
  background-color: rgba(187, 216, 255, 0.3);
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
  border-style: solid;
  border-color: rgba(80, 100, 120, 0);
  transition: all ease-in-out 0.3s;
}
.bubble:hover {
  background-color: rgba(249, 252, 255, 0.8);
  border-color: rgba(134, 156, 175, 0.24);
  /* transform: translate(-1px, -1px); */
}
.bubble-title {
  font-weight: 800;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 10%;
  font-size: 20px;
  color: rgba(80, 100, 120, 0.9);
  font-family: "hanyi", -apple-system, BlinkMacSystemFont, Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.bubble-quote::before {
  content: "“";
  color: rgba(128, 157, 255, 0.5);
  display: flex;
  justify-self: flex-start;
  align-items: flex-start;
  padding-left: 30px;
  padding-right: 5px;
  font-size: 40px;
  font-weight: 800;
  font-family: "hanyi", -apple-system, BlinkMacSystemFont, Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.bubble-quote::after {
  content: "”";
  color: rgba(128, 157, 255, 0.5);
  display: flex;
  justify-self: flex-end;
  align-items: flex-end;
  padding-left: 5px;
  padding-right: 30px;
  font-size: 40px;
  font-weight: 800;
  font-family: "hanyi", -apple-system, BlinkMacSystemFont, Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.bubble-quote {
  text-align: left;
  font-weight: 100;
  font-size: 18px;
  color: rgba(80, 100, 120, 0.9);
  letter-spacing: 1px;
  display: flex;
  padding: auto;
  width: 100%;
  font-family: "hanyi", Calibri, "Trebuchet MS", sans-serif;
  word-break: break-all;
}
.timestamp {
  text-align: end;
  font-size: 12px;
  letter-spacing: 1px;
  font-weight: 100;
  color: rgba(80, 120, 160, 0.7);
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif,
    Courier, monospace;
}
</style>