<template>
<page-header></page-header>
<time-line ></time-line>
</template>

<script>
import TimeLine from './components/TimeLine.vue'
import PageHeader from './components/PageHeader.vue'
export default {
  name: "App",
  data() {
    return {
    };
  },
  components: {
    TimeLine,
    PageHeader,
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Noto+Serif+SC");
@font-face {
    font-family: "hanyi";
    src: url("~@/assets/HYJinLingKeJingW.woff");
}
html{
  background-color: rgba(221, 238, 255, 0.7);
  margin: 0px;
  padding: 0px;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  margin: 0px;
  padding: 0px;
}
</style>
