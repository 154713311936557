<template>
  <div class="timeline">
    <quote-bubble
      v-for="post in posts"
      :title="post.title"
      :quote="post.message"
      :time="post.timestamp"
      :key="post"
    ></quote-bubble>
  </div>
</template>

<script>
import QuoteBubble from "./QuoteBubble.vue";
export default {
  data() {
    return {
      posts: [
      ],
    };
  },
  async mounted() {
        const axios = require('axios').default;
        await axios.get("https://s.kkk.re/posts")
            .then((response) => {
                this.posts.length = 0;
                var newposts = response.data.posts;
                for (var {title, message, timestamp} of newposts) {
                    this.posts.push({title: title, message: message, timestamp: timestamp})
                }
            });
  },
  components: {
    QuoteBubble,
  },
};
</script>

<style>
.timeline {
  min-width: 400px;
  max-width: 700px;
  margin: auto;
}
</style>